'use strict';

 // Uncomment to enable Bootstrap tooltips
// https://getbootstrap.com/docs/4.0/components/tooltips/#example-enable-tooltips-everywhere
// $(function () { $('[data-toggle="tooltip"]').tooltip(); });
// Uncomment to enable Bootstrap popovers
// https://getbootstrap.com/docs/4.0/components/popovers/#example-enable-popovers-everywhere
// $(function () { $('[data-toggle="popover"]').popover(); });

(function ($) {
  'use strict'; //   var headerHeight = $('.c-header').height();

  function scroll_header() {
    $(window).scroll(function () {
      // console.log(headerHeight);
      if ($(this).scrollTop() > $('.c-banner').offset().top) {
        // console.log('fixed');
        $('.c-header').addClass('headerShow');
        $('body').addClass('headerfixed'); // $('body').css("padding-top","(' + headerHeight + 'px)");
      } else {
        $('.c-header').removeClass('headerShow');
        $('body').removeClass('headerfixed');
      }
    });
  }

  scroll_header(); // window.onscroll = function() {myFunction()};
  // var header = document.getElementsByClassName("c-header");
  // var sticky = header.offsetTop;
  // function myFunction() {
  // if (window.pageYOffset > sticky) {
  //     header.classList.add("sticky");
  // } else {
  //     header.classList.remove("sticky");
  //     }
  // }

  function toggle_button() {
    $('.c-header__toggle-button').on('click', function () {
      $(this).toggleClass('active');
      $('body').toggleClass('Is-toggle');
    });
  }

  toggle_button();
  /*    $('.c-header__menu-item--dropdown').on('click', function () {
         $(this).toggleClass('active');
     }) */

  function toggle_search() {
    $('.search-link').on('click', function () {
      $(this).toggleClass('active');
      $('.c-header__search-container').toggleClass('show');
    });
  }

  toggle_search();

  $('.search-toggle').addClass('closed');

$('.search-toggle .search-toggle__btn').click(function(e) {
  if ($('.search-toggle').hasClass('closed')) {
    $('.search-toggle').removeClass('closed').addClass('opened');
    $('.search-toggle, .search-container').addClass('opened');
    $('#search-terms').focus();
  } else {
    $('.search-toggle').removeClass('opened').addClass('closed');
    $('.search-toggle, .search-container').removeClass('opened');
  }
});

function top_header_link(){
    $('.c-header__menu li.c-header__menu-item--dropdown .c-header__menu-link').on('click', function () {
        $('.c-header__menu li.c-header__menu-item--dropdown .c-header__menu-link').not(this).parent('li').removeClass('active');
        $(this).parent('li').toggleClass('active');
    });

    $(document).on('click', function(event){
        var $trigger = $('.c-header__menu li.c-header__menu-item--dropdown');
        if($trigger !== event.target && !$trigger.has(event.target).length){
            $('.c-header__menu li.c-header__menu-item--dropdown').removeClass('active');
        }            
    });

  }

  top_header_link();
  /*  function top_header_link(){
       $('.c-header__menu li.c-header__menu-item--dropdown').on('click', function(){
         
         $(this).toggleClass('active');
     })
   }
   top_header_link(); */

  $('.c-form--form-control.select').each(function () {
    var $this = $(this),
        numberOfOptions = $(this).children('option').length;
    $this.addClass('select-hidden');
    $this.wrap('<div class="select"></div>');
    $this.after('<div class="select-styled"></div>');
    var $styledSelect = $this.next('div.select-styled');
    $styledSelect.text($this.children('option').eq(0).text());
    var $list = $('<ul />', {
      class: 'select-options'
    }).insertAfter($styledSelect);

    for (var i = 0; i < numberOfOptions; i++) {
      $('<li />', {
        text: $this.children('option').eq(i).text(),
        rel: $this.children('option').eq(i).val()
      }).appendTo($list);
    }

    var $listItems = $list.children('li');
    $styledSelect.click(function (e) {
      e.stopPropagation();
      $('div.select-styled.active').not(this).each(function () {
        $(this).removeClass('active').next('ul.select-options').hide();
      });
      $(this).toggleClass('active').next('ul.select-options').toggle();
    });
    $listItems.click(function (e) {
      e.stopPropagation();
      $styledSelect.text($(this).text()).removeClass('active');
      $this.val($(this).attr('rel'));
      $list.hide(); //console.log($this.val());
    });
    $(document).click(function () {
      $styledSelect.removeClass('active');
      $list.hide();
    });
  });
})(window.jQuery);
